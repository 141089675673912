import { createClient } from '@supabase/supabase-js';

const TAG_LIMIT = 1000;
export const supabase = createClient(import.meta.env.VITE_SUPABASE_URL, import.meta.env.VITE_SUPABASE_ANON_KEY, {
    auth: {
        flowType: 'pkce'
    }
});

export const SupabaseService = {
    SCOPES: ['user-library-read', 'user-modify-playback-state', 'user-read-private', 'user-read-currently-playing', 'playlist-read-private', 'playlist-modify-public', 'playlist-modify-private'],
    DEFAULT_LIMIT: 50,

    async signInWithOAuth() {
        const { error } = await supabase.auth.signInWithOAuth({
            provider: 'spotify',
            options: {
                scopes: SupabaseService.SCOPES.join(', ')
            }
        });
        if (error) throw error;
    },

    async refreshSession() {
        console.log('Refreshing session');
        const { data, error } = await supabase.auth.refreshSession();
        if (error) throw error;
        sessionStorage.setItem('session', JSON.stringify(data.session));
    },

    async signOut() {
        const { error } = await supabase.auth.signOut();
        if (error) throw error;
    },

    async getTags() {
        const { data, error } = await supabase.from('tags').select('id, name').order('name').limit(TAG_LIMIT);
        if (error) throw error;
        return data;
    },

    async getTrackTagsBySpotifyId() {
        const { data, error } = await supabase.from('track_tags_view_by_spotify_id').select('spotify_id, tag_ids');
        if (error) throw error;
        return data;
    },

    async getTrackIdsByTagIds(tagIds, offset, limit) {
        if (!Array.isArray(tagIds)) tagIds = [tagIds];
        offset = offset || 0;
        limit = limit ? Math.max(limit, this.DEFAULT_LIMIT) : this.DEFAULT_LIMIT;
        const { data, error } = await supabase.rpc('get_spotify_ids_by_tag_ids', { tag_ids: tagIds, offset_val: offset, limit_val: limit });
        if (error) {
            error.tagIds = tagIds;
            throw error;
        }
        if (data === null || data?.length === 0) return [];
        return data[0];
    },

    async createTag(newTagName) {
        const { data, error } = await supabase
            .from('tags')
            .upsert({
                name: newTagName
            })
            .select('id, name')
            .order('name');
        if (error) {
            error.newTagName = newTagName;
            throw error;
        }
        return data;
    },

    async updateTag(tagId, newTagName) {
        const { data, error } = await supabase
            .from('tags')
            .update({
                name: newTagName
            })
            .eq('id', tagId)
            .select('id, name')
            .order('name');
        if (error) {
            error.tagId = tagId;
            error.newTagName = newTagName;
            throw error;
        }
        return data;
    },

    async upsertTag(tagId, tagName) {
        const { data, error } = await supabase
            .from('tags')
            .upsert(
                {
                    id: tagId,
                    name: tagName
                },
                { onConflict: 'id' }
            )
            .select('id, name')
            .order('name');
        if (error) {
            error.tagId = tagId;
            error.tagName = tagName;
            throw error;
        }
        return data;
    },

    async insertTags(tagNames) {
        const tagData = tagNames.map((tagName) => ({ name: tagName }));
        const { data, error } = await supabase.from('tags').insert(tagData).select('id, name').order('name');
        if (error) {
            error.tagNames = tagNames;
            throw error;
        }
        return data;
    },

    async deleteTags(tagIds) {
        const { data, error } = await supabase.from('tags').delete().in('id', tagIds).select('id, name').order('name');
        if (error) throw error;
        return data;
    },

    async tagTrack(trackId, tagId, added) {
        if (!added) {
            const { data, error } = await supabase.from('track_tags').delete().eq('spotify_id', trackId).eq('tag_id', tagId);
            if (error) {
                error.trackId = trackId;
                error.tagId = tagId;
                error.added = added;
                throw error;
            }
            return data;
        } else {
            const { data, error } = await supabase.from('track_tags').insert({
                spotify_id: trackId,
                tag_id: tagId
            });
            if (error) {
                error.trackId = trackId;
                error.tagId = tagId;
                error.added = added;
                throw error;
            }
            return data;
        }
    },

    async tagTracks(trackIds, tagIds, added) {
        if (!added) {
            const { data, error } = await supabase.from('track_tags').delete().in('spotify_id', trackIds).in('tag_id', tagIds);
            if (error) {
                error.trackIds = trackIds;
                error.tagIds = tagIds;
                error.added = added;
                throw error;
            }
            return data;
        } else {
            const tagTrackData = trackIds.map((trackId) => tagIds.map((tagId) => ({ spotify_id: trackId, tag_id: tagId }))).flat();
            const { data, error } = await supabase.from('track_tags').upsert(tagTrackData, { ignoreDuplicates: true, onConflict: ['spotify_id', 'tag_id'] });
            if (error) {
                error.trackIds = trackIds;
                error.tagIds = tagIds;
                error.added = added;
                throw error;
            }
            return data;
        }
    },

    async upsertTrackTags(trackTags) {
        const { data, error } = await supabase.from('track_tags').upsert(trackTags, { ignoreDuplicates: true, onConflict: ['spotify_id', 'tag_id'] });
        if (error) {
            error.trackTags = trackTags;
            throw error;
        }
        return data;
    },

    async saveFeedback(comment) {
        const { data, error } = await supabase.from('feedback').insert({ comment: comment }).select();
        if (error) throw error;
        return data;
    },

    async deleteAccount() {
        const { data, error } = await supabase.functions.invoke('delete-user');
        if (error) throw error;
        return data;
    }
};
