import '@/assets/styles.scss';
import '@/assets/tailwind.css';

import { SpotifyService } from '@/service/SpotifyService';
import { SupabaseService } from '@/service/SupabaseService';
import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';
import * as Sentry from '@sentry/vue';
import axios from 'axios';
import { throttle } from 'lodash-es';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import { createApp } from 'vue';
import VueGtag from 'vue-gtag-next';
import App from './App.vue';
import router from './router';

const stylePreset = definePreset(Aura, {
    semantic: {
        primary: {
            50: '{green.50}',
            100: '{green.100}',
            200: '{green.200}',
            300: '{green.300}',
            400: '{green.400}',
            500: '{green.500}',
            600: '{green.600}',
            700: '{green.700}',
            800: '{green.800}',
            900: '{green.900}',
            950: '{green.950}'
        },
        colorScheme: {
            dark: {
                surface: {
                    0: '#ffffff',
                    50: '{neutral.50}',
                    100: '{neutral.100}',
                    200: '{neutral.200}',
                    300: '{neutral.300}',
                    400: '{neutral.400}',
                    500: '{neutral.500}',
                    600: '{neutral.600}',
                    700: '{neutral.700}',
                    800: '{neutral.800}',
                    900: '{neutral.900}',
                    950: '{neutral.950}'
                }
            }
        }
    }
});

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(PrimeVue, {
    theme: {
        preset: stylePreset,
        options: {
            darkModeSelector: '.app-dark'
        }
    }
});
app.use(ConfirmationService);
app.use(ToastService);
app.use(VueGtag, {
    property: [{ id: import.meta.env.VITE_GOOGLE_TAG_ID }, { id: import.meta.env.VITE_GOOGLE_ADWORDS_ID }]
});

Sentry.init({
    app,
    environment: import.meta.env.VITE_ENVIRONMENT,
    dsn: import.meta.env.VITE_SENTRY_DSN, // Set this only in the environments we want to report, per https://github.com/getsentry/sentry-javascript/discussions/8546#discussioncomment-6556295
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/tagatrack\.com\/.*/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ['Event `Event` (type=error) captured as promise rejection'] // See https://github.com/getsentry/sentry-javascript/issues/2546#issuecomment-1727939255
});

router.onError((error, to) => {
    // To fix errors with chunk filenames on deployment, per https://stackoverflow.com/a/74861436
    if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes('Importing a module script failed')) {
        window.location = to.fullPath;
    }

    Sentry.captureException(error);
    // TODO: Do we want to redirect to an error page here?
    // router.push({ name: 'error' });
});

axios.interceptors.request.use((config) => {
    return new Promise(throttle((resolve) => resolve(config), 2000));
});

axios.interceptors.response.use(null, async (error) => {
    if (error.config.url.startsWith(SpotifyService.BASE_URL) && error.response?.status === 401) {
        // Refreshing doesn't currently work... see SpotifyService.refreshToken().
        // We'll just require the user to sign in again for now... ugh...
        SupabaseService.signInWithOAuth();
        return;
        // const newToken = await SpotifyService.refreshToken().access_token;
        // const newRequest = error.config;
        // newRequest.headers['Authorization'] = `Bearer ${newToken}`;
        // return axios(newRequest);
    }

    if (error.config.url.startsWith(SpotifyService.BASE_URL) && error.response?.status === 403) {
        router.push({ name: 'unauthorized' });
        return;
    }

    if (error.config.url.startsWith(SpotifyService.BASE_URL) && error.response?.status === 429) {
        router.push({ name: 'error' });
        return;
    }

    return Promise.reject(error);
});

app.mount('#app');
